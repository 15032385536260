import { useEffect, useState } from 'react';
import { defaultParams, type ShaderParams } from './params';
import { Canvas } from './canvas';
import logo from './assets/doges-logo.png';
import { parseLogoImage } from './parse';

const state = { ...defaultParams, background: 'black' };

export function Hero() {
  const [imageData, setImageData] = useState<ImageData | null>(null);

  useEffect(() => {
    async function updateImageData() {
      try {
        const res = await fetch(logo);
        const blob = await res.blob();
        const file = new File([blob], "logo.png");
        const bitmap = await parseLogoImage(file);
        setImageData(bitmap);
      } catch (error) {
        console.error(error);
      }
    }
    updateImageData();
  }, []);

  return (
    <div className="h-full bg-black">
      <Canvas
        imageData={imageData}
        params={state}
        className={imageData ? "opacity-100" : "opacity-0"}
      />
    </div>
  );
}
