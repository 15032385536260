export type ShaderParams = {
  patternScale: number;
  refraction: number;
  edge: number;
  patternBlur: number;
  liquid: number;
  speed: number;
};

export const params = {
  refraction: 0.02,
  edge: 0.2,
  patternBlur: 0.02,
  liquid: 0.03,
  speed: 0.3,
  patternScale: 3,
};

/** The default params for the shader in a ShaderParams object */
export const defaultParams: ShaderParams = Object.fromEntries(
  Object.entries(params).map(([key, value]) => [key, value])
) as ShaderParams;